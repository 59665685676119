<template>
	<div class="Admins">
		<div class="Admins-T">
			<div class="AdminsL">
				<Breadcrumb></Breadcrumb>
				<div class="RightsManagement-searchs">
					<div class="RightsManagement-searchs1">
						<inputs @inputs="inputValue" :inputShow="true" inputPlace="请输入名称"></inputs>
					</div>
					<div class="Adminsit-mai1-btn">
						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div>
			</div>
			<div class="AdminsR">
				<div class="RightsManagement-searchsMai">
					<!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
					<el-button type="primary" v-permission="'Admin:Add'" @click="clickNew()" icon="el-icon-plus">新增人员</el-button>
				</div>
			</div> 
		</div>
		<div class="Admins-B">
			<el-table :data="tableData" border style="width: 100%" size="small">
				<template slot="empty">
					<noData></noData>
				</template>
				<el-table-column type="index" width="50" label="序号">
				</el-table-column>
				<el-table-column prop="T_user" label="用户名" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column label="权限" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.T_power_name}}
					</template>
				</el-table-column>
				<el-table-column label="名称" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.T_name}}
					</template>
				</el-table-column>
				<el-table-column prop="address" label="操作" fixed="right" width="250">
					<template slot-scope="scope">
						<el-button size="small" v-permission="'AdminCompany:Bind'" @click="allpir('a',scope.row)">绑定</el-button>
						<el-button size="small" v-permission="'Admin:Edit'" @click="allpir('b',scope.row)">编辑</el-button>
						<el-button size="small" v-permission="'Admin:Del'" @click="allpir('c',scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="RightsManagement-pagination">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page="pages.page" :page-size="pages.page_z"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		<el-drawer title="绑定" :visible.sync="drawer" @close="closes" direction="rtl" size="50%" :append-to-body="true">
			<div style="padding:0 20px;">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="已绑定公司" name="first">
						<el-table :data="tableData1" style="width: 100%;margin-bottom: 20px;" row-key="Id" border
							:tree-props="{children: 'Children', hasChildren: 'hasChildren'}">
							<template slot="empty">
								<noData></noData>
							</template>
							<el-table-column prop="T_name" label="公司名称" :show-overflow-tooltip="true">
							</el-table-column>
							<el-table-column prop="T_name" label="公司名称" :show-overflow-tooltip="true">
								<template slot-scope="scope">
									<el-button type="primary" icon="el-icon-delete" size="small"
										@click="SetBindDelApi(scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<!-- <div style="margin-top: 20px;">
							<el-pagination :hide-on-single-page="total1==0?true:false" background small
								layout="prev, pager, next" :total="total1" style="padding: 2px 0;">
							</el-pagination>
						</div> -->
					</el-tab-pane>
					<el-tab-pane label="公司列表" name="second">
						<el-input v-model="pages1.T_name" placeholder="请输入内容" style="margin-bottom: 10px;width:80%;">
							<el-button slot="append" type="primary" icon="el-icon-search" @click="searchList">搜索</el-button>
						</el-input>
						<el-table :data="tableData2" style="width: 100%;margin-bottom: 20px;" row-key="Id" border
							:tree-props="{children: 'Children', hasChildren: 'hasChildren'}">
							<template slot="empty">
								<noData></noData>
							</template>
							<el-table-column prop="T_name" label="公司名称" :show-overflow-tooltip="true">
							</el-table-column>
							<el-table-column prop="T_name" label="公司名称" :show-overflow-tooltip="true">
								<template slot-scope="scope">
									<el-button type="primary" label="操作" icon="el-icon-paperclip" size="small"
										@click="SetBindAddApi(scope.row)">绑定</el-button>
								</template>
							</el-table-column>
						</el-table>
						<!-- <div style="margin-top: 20px;">
							<el-pagination :hide-on-single-page="total2==0?true:false" background small
								layout="prev, pager, next" :total="total2" style="padding: 2px 0;">
							</el-pagination>
						</div> -->
						<!-- <el-empty description="暂无更多内容"></el-empty> -->
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-drawer>
		<el-dialog :title="titles" :visible.sync="dialogVisible" :append-to-body="true">
			<div class="NewUser">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm">
					<el-form-item label="用户名" prop="T_user">
						<el-input v-model="ruleForm.T_user" placeholder="请输入用户名"></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="T_pass">
						<el-input v-model="ruleForm.T_pass" show-password
							:placeholder="condition=='add'?'请输入密码':'不填写则不修改密码'"></el-input>
					</el-form-item>
					<el-form-item label="名称" prop="T_name">
						<el-input v-model="ruleForm.T_name" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="权限" prop="T_power">
						<el-select v-model="ruleForm.T_power" placeholder="请选择权限">
							<el-option :label="item.T_name" :value="item.Id" v-for="(item,index) in autList"
								:key="index"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
						<el-button @click="dialogVisible=false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		AdminList, //列表
		AdminAdd, //新增
		AdminEdit, //编辑
		AdminDel, //删除
		PowerList, //权限select
		CompanyList, //公司列表
		CompanyBindList, //已绑定公司列表
		CompanyBindAdd, //添加公司绑定
		CompanyBindDel, //删除公司绑定
	} from '@/api/AdminManagement/Admin.js'
	export default {
		data() {
			return {
				Page_size:null,
				tableData: [],
				pages: {
					T_name: '',
					page: 1,
					page_z: 10,
				},
				total:0,
				pages1:{
					T_name:'',
					page: 1,
					page_z: 10,
				},
				ruleForm: { //新增
					T_power: '', // 权限
					T_user: '', //用户名
					T_pass: '', //密码
					T_name: '', //名称
				},
				condition: null, //用于判断点击的是新增还是编辑
				autList: [], //权限select框
				ruleFormbind: { //已经绑定公司提交参数
					T_uuid: '',
					page: 1,
					page_z: 10,
				},
				ruleForm1: { //删除/绑定公司提交参数
					T_uuid: '',
					T_pid: null
				},
				total1: 0,
				total2: 0,
				tableData1: [], //已绑定公司列表
				tableData2: [], //公司列表
				activeName: 'first',
				drawer: false,
				titles: '',
				dialogVisible: false,
				rules: {
					T_user: [{
						required: true,
						message: '请填写用户名（不能填写中文）',
						pattern: /^[a-zA-Z0-9_]{0,}$/,
						trigger: 'blur'
					}],
					T_pass: [{
						required: true,
						message: '请填写密码（字母+数字，8-18位）',
						// pattern: /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{8,18}$/,//大写+小写+数字
						pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].{7,18}$/,//字母+数字
						trigger: 'blur'
					}],
					T_name: [{
						required: true,
						message: '请填写名称',
						trigger: 'blur'
					}],
					T_power: [{
						required: true,
						message: '请选择权限',
						trigger: 'change'
					}],
				}
			}
		},
		mounted() {
			this.getAdminListApi()
		},
		methods: {
			handleSizeChange(e) { //每页多少条
				////console.log('每页多少条',e)
				this.pages.page_z = e
				this.getAdminListApi()
			},
			handleCurrentChange(e) { //跳转页
				////console.log('跳转页',e)
				this.pages.page = e
				this.getAdminListApi()
			},
			
			closes(){
				this.tableData1 = []
				this.activeName = 'first'
			},
			searchList(){
				this.pages1.page = 1
				this.GetCompanyListApi()
			},
			GetCompanyListApi() { //公司列表
				CompanyList(this.pages1).then(res => {
					if (res.data.Code == 200) {
						this.tableData2 = res.data.Data.Data
						////console.log('公司列表', res,this.tableData2)
						this.total2 = res.data.Data.Num
					}
				})
			},
			GetCompanyBindListApi() { //已绑定公司列表
				CompanyBindList(this.ruleFormbind).then(res => {
					////console.log('已绑定公司列表', res)
					if (res.data.Code == 200) {
						if(res.data.Data.Data!=null){
							this.tableData1 = res.data.Data.Data
						}else{
							this.tableData1 = []
						}
						this.total1 = res.data.Data.Num
					}
				})
			},
			SetBindAddApi(obj) { //添加公司绑定
				this.ruleForm1.T_pid = obj.Id
				CompanyBindAdd(this.ruleForm1).then(res => {
					if (res.data.Code === 200) {
						this.$message.success('操作成功，绑定公司成功')
					}
				})
			},
			SetBindDelApi(obj) { //删除公司绑定
				this.ruleForm1.T_pid = obj.Id
				CompanyBindDel(this.ruleForm1).then(res => {
					if (res.data.Code === 200) {
						this.$message.success('操作成功，删除公司绑定')
						this.GetCompanyBindListApi()
					}
				})
			},
			getAdminListApi() { //人员列表
				AdminList(this.pages).then(res => {
					////console.log('人员列表',res)
					this.Page_size = res.data.Data.Page_size
					this.total = res.data.Data.Num
					if (res.data.Code == 200) {
						this.tableData = res.data.Data.Data
					}
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						var obj
						this.condition == 'Edit' ? obj = await this.AdminEditApi() : obj = await this.AdminAddApi()
						if (obj.data.Code === 200) {
							this.condition == 'Edit' ? this.$message.success('编辑信息成功') : this.$message.success('新增人员成功')
							this.dialogVisible = false
							this.tableData = []
							this.pages.page = 1
							this.getAdminListApi()
						}
					} else {
						return false;
					}
				});
			},
			AdminAddApi() { //新增
				var obj = {
					...this.ruleForm
				}
				obj.T_pass = this.$md5(obj.T_pass)
				////console.log('新增', obj)
				return new Promise(resolve => {
					AdminAdd(obj).then(res => {
						////console.log('新增人员', res)
						resolve(res)
					})
				})
			},
			AdminEditApi() { //编辑
				var obj = {
					...this.ruleForm
				}
				obj.T_pass = obj.T_pass==''?'':this.$md5(obj.T_pass)
				return new Promise(resolve => {
					AdminEdit(obj).then(res => {
						////console.log('编辑人员', res)
						resolve(res)
					})
				})
			},
			AdminDelApi(item) {
				return new Promise(resolve => {
					AdminDel({
						T_uuid: item.T_uuid
					}).then(res => {
						////console.log('删除人员', res)
						resolve(res)
					})
				})
			},
			PowerListApi() { //权限select
				PowerList({}).then(res => {
					if (res.data.Code == 200) {
						this.dialogVisible = true
						this.autList = res.data.Data.Data
					}
				})
			},
			handleClick(tab) {
				////console.log(tab.name)
				tab.name == 'first' ? this.GetCompanyBindListApi() : this.GetCompanyListApi()

			},
			allpir(i, item) {
				if (i === "a") { //绑定
					this.ruleFormbind.T_uuid = item.T_uuid
					this.ruleForm1.T_uuid = item.T_uuid
					this.GetCompanyBindListApi()
					this.drawer = true
				} else if (i === "b") { //编辑
					if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
						this.$refs.ruleForm.resetFields();
					}
					this.dialogVisible = true
					this.titles = "编辑"
					this.condition = 'Edit'
					this.PowerListApi()
					this.rules.T_pass[0].required = false
					this.ruleForm.T_pass = item.T_pass // 权限
					this.ruleForm.T_power = item.T_power // 权限
					this.ruleForm.T_user = item.T_user // 用户名
					this.ruleForm.T_name = item.T_name // 名称
					this.ruleForm.T_uuid = item.T_uuid
				} else { //删除
					this.$confirm('此操作将执行删除, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
						center: true
					}).then(async () => {
						var obj = await this.AdminDelApi(item)
						if (obj.data.Code === 200) {
							this.$message.success('操作成功，人员已经删除')
							this.tableData = []
							this.pages.page = 1
							this.getAdminListApi()
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消操作'
						});
					});
				}
			},
			inputValue(e) {
				////console.log('查询', e)
				this.pages.T_name = e
				this.tableData = []
				this.pages.page = 1
				this.getAdminListApi()
			},
			clickNew() { //新增
				////console.log('检查', this.$refs.ruleForm)
				if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
					this.$refs.ruleForm.resetFields();
				}
				this.rules.T_pass[0].required = true
				this.condition = 'add'
				this.PowerListApi()
				this.titles = "新增"
				Object.keys(this.ruleForm).forEach(key => (this.ruleForm[key] = ''))
				////console.log('新增', this.ruleForm)
				// let params = Object.keys(form).forEach(key => (form[key] = ""));
				// ////console.log(params);

			},
		}
	}
</script>

<style lang="scss">
	.Admins {
		height: 100%;
		overflow: hidden;
		.Admins-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;
			.AdminsL {
				display: flex;
				align-items: center;

				.RightsManagement-searchs {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 20px;

					.Adminsit-mai1-btn {}

					.RightsManagement-searchs1 {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.RightsManagement-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}

		.Admins-B {
			background: rgba(#fff, 1);
			margin: 10px;
			padding: 0 30px;
			// height: calc(100vh - 120px);
			overflow-y: auto;
		}
		.RightsManagement-pagination {
			// background: rgba(#31353e,1);
			padding:50px 30px;
			border-radius: 2px;
		}
	}
</style>
