// 登录接口
import {
	axios
} from '@/utils/http.js'

const AdminApi = {
	AdminList: '/v3/Admin/List',//内部管理列表
	AdminAdd: '/v3/Admin/Add',//新增
	AdminEdit: '/v3/Admin/Edit',//编辑
	AdminDel: '/v3/Admin/Del',//删除
	
	PowerList: '/v3/Power/List_All',//添加/修改用户 - 权限选择列表
	
	CompanyList: '/v3/Company/List',//公司列表
	CompanyBindList: '/v3/Admin/CompanyBind_List',//已绑定公司列表
	CompanyBindAdd: '/v3/Admin/CompanyBind_Add',//添加公司绑定
	CompanyBindDel: '/v3/Admin/CompanyBind_Del',//删除公司绑定
	
}
//公司列表
export function CompanyList(data) {
	return axios({
		url: AdminApi.CompanyList,
		method: 'post',
		data:data
	})
}
//已绑定公司列表
export function CompanyBindList(data) {
	return axios({
		url: AdminApi.CompanyBindList,
		method: 'post',
		data:data
	})
}
//添加公司绑定
export function CompanyBindAdd(data) {
	return axios({
		url: AdminApi.CompanyBindAdd,
		method: 'post',
		data:data
	})
}
//删除公司绑定
export function CompanyBindDel(data) {
	return axios({
		url: AdminApi.CompanyBindDel,
		method: 'post',
		data:data
	})
}



//添加/修改用户 - 权限选择列表
export function PowerList(data) {
	return axios({
		url: AdminApi.PowerList,
		method: 'post',
		data:data
	})
}
//编辑
export function AdminDel(data) {
	return axios({
		url: AdminApi.AdminDel,
		method: 'post',
		data:data
	})
}
//编辑
export function AdminEdit(data) {
	return axios({
		url: AdminApi.AdminEdit,
		method: 'post',
		data:data
	})
}

//新增
export function AdminAdd(data) {
	return axios({
		url: AdminApi.AdminAdd,
		method: 'post',
		data:data
	})
}
//内部管理列表
export function AdminList(data) {
	return axios({
		url: AdminApi.AdminList,
		method: 'post',
		data:data
	})
}